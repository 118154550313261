<template>
  <header class="InvoiceHeader">
    <div class="InvoiceHeader__logo">
      <IconLogo />
    </div>

    <div class="InvoiceHeader__brand">
      <h2>Cryptos fund Trading</h2>
      <!--<h3>Stop Holding. Go Trading!</h3>-->
    </div>

    <div class="InvoiceHeader__invoice">
      <h1>Invoice</h1>
    </div>
  </header>
</template>

<script>
  import IconLogo from '@/components/IconLogo'

  export default {
    components: {
      IconLogo
    },

    props: {
      invoice: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoiceHeader
    break-after: avoid-page
    display: flex
    align-items: center
    gap: $spacer
    padding: $spacer-5
    font-size: 14px

  .InvoiceHeader__logo
    width: 75px

    :deep(svg) *
      fill: $tertiary

  .InvoiceHeader__brand
    flex: 1 1 auto
    display: flex
    flex-direction: column
    justify-content: center

    h2,
    h3
      margin: 0

    h2
      text-transform: uppercase
      font-size: 1.5em

    h3
      font-size: 1.2em
      font-weight: normal
      color: black

  .InvoiceHeader__invoice
    text-transform: uppercase

    h1
      font-size: 1.8em
</style>
