<template>
  <div class="InvoicePeriod" v-if="invoice">
    <h3>
      Service Period
      {{ serviceDate }}
    </h3>

    <table class="InvoicePeriod__table InvoicePeriod__table__main table">
      <colgroup>
        <col>
        <col style="width:150px">
      </colgroup>

      <thead>
        <tr>
          <th>Item description</th>
          <th class="text-end">Total</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            Account Balance (Last Statement)
            <time>{{ statementDate }}</time>
          </td>
          <td class="text-end">
            ${{ formatDecimal(invoice.statements.previous) }}
          </td>
        </tr>

        <tr>
          <td>
            Period deposit
          </td>
          <td class="text-end">
            ${{ formatDecimal(invoice.period.deposit) }}
          </td>
        </tr>

        <tr>
          <td>
            Period withdraw
          </td>
          <td class="text-end">
            ${{ formatDecimal(invoice.period.withdraw) }}
          </td>
        </tr>

        <tr>
          <td>
            Account Balance
            <time>{{ date }}</time>
          </td>
          <td class="text-end">
            ${{ formatDecimal(invoice.statements.current) }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="InvoicePeriod__table InvoicePeriod__table__footer table">
      <colgroup>
        <col>
        <col style="width:200px">
        <col style="width:150px">
      </colgroup>
      <tfoot>
        <tr>
          <td class="InvoicePeriod__table__notes" rowspan="6">
            <div class="InvoicePeriod__notes">
              <p>
                <strong>
                  New Equity / Statement / {{ date }} = ${{ formatDecimal(invoice.statements.current) }} (if payment by bank)
                  or
                  ${{ formatDecimal(invoice.statements.current) }} - ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }} = ${{ formatDecimal(invoice.statements.new) }}
                  (if payment from your sub-accounts wallets).
                </strong>
              </p>

              <p>
                <b>Execution Service Fee – Performance-Based (Monthly Settlement)</b>
                The Execution Service Fee is a variable remuneration for API-based trade execution services, calculated based on realized and unrealized gains during the settlement period. This fee applies solely to execution services provided under the agreed terms of the Trading Execution Agreement.
              </p>
              <p>
                The fee is applicable only if the final Market Value of the cryptocurrency portfolio at the end of the settlement period (30 days or monthly) exceeds its Market Value at the start of the period (in USD, USDC or USDT Tether). Additionally, the fee is only generated if this value also surpasses the Initial Settlement Performance Value (ISPV) set in the inception month. The calculation and application of this fee are governed by Article 13 of the Trading Execution Agreement.<br/>
                C.FT Ltd exclusively provides API-based trade execution services. We do not offer financial advisory, portfolio management, or custodial services. All trades are executed strictly per client instructions under the Trading Execution Agreement.
              </p>
              <p>
                By proceeding with this payment, the client acknowledges that:<br/>
                ✅ All fees are non-refundable.<br/>
                ✅ The services rendered under this invoice are execution-based only.<br/>
                ✅ Any disputes related to this invoice must be raised within 7 days of the invoice date.
              </p>
            </div>
          </td>

          <td>
            Performance
          </td>

          <td class="text-end">
            ${{ formatDecimal(invoice.period.performance) }}
          </td>
        </tr>

        <tr>
          <td>Commission {{ formatDecimal(invoice.settings.performance_fee) }}%</td>
          <td class="text-end">
            ${{ formatDecimal(invoice.due.performance_fee) }}
          </td>
        </tr>

        <tr>
          <td>
            Quarterly Infrastructure & Maintenance Fee {{ formatDecimal(invoice.settings.quaterly_fee) }}% of the total account balance, covering API integration, execution infrastructure, and operational support
            <time v-if="invoice.due.quaterly_fee !== null">{{ periodDates }}</time>
          </td>
          <td class="text-end">
            ${{ formatDecimal(invoice.due.quaterly_fee || 0) }}
          </td>
        </tr>

        <tr>
          <td>Total</td>
          <td class="text-end">
            ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }}
          </td>
        </tr>

        <tr>
          <th>Total Due</th>
          <th class="text-end">
            ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }}
          </th>
        </tr>

        <tr class="InvoicePeriod__table__vat" v-if="invoice.global_settings.invoice_vat_info">
          <td colspan="2">
            {{ invoice.global_settings.invoice_vat_info }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { addDays } from 'date-fns'

  export default {
    props: {
      invoice: {
        type: Object,
        default: null
      }
    },

    computed: {
      date() {
        return formatDate(this.invoice.date, 'MMMM do, yyyy')
      },

      statementDate() {
        return formatDate(this.invoice.period.date, 'MMMM do, yyyy')
      },

      serviceDate() {
        return formatDate(this.invoice.period.date, 'MMMM yyyy')
      },

      periodDates() {
        const date = new Date(this.invoice.period.date)

        return formatDate(date, 'MMMM yyyy') + ' - ' + formatDate(addDays(date, 63), 'MMMM yyyy')
      }
    },

    methods: {
      formatDate,
      formatDecimal
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoicePeriod
    break-after: avoid-page
    padding: $spacer $spacer-5

    h3
      font-size: 1.8em

  .InvoicePeriod__table
    vertical-align: middle
    font-size: 1.1em

    thead
      td, th
        background: $primary
        color: white

    tbody
      th, td
        font-weight: bold

      tr:last-child
        th, td
          border-bottom: 0

      time
        display: block
        font-weight: normal

    tfoot
      th, td
        font-weight: bold
        color: $primary

      td:last-child
        color: $body-color

      th
        color: white
        background: $primary
        text-transform: uppercase
        font-size: 1.2em

  tbody, tfoot
    tr
      height: 4em

  .InvoicePeriod__table__notes
    border-bottom-color: transparent
    vertical-align: top

  .InvoicePeriod__notes
    font-weight: normal
    font-size: 9px
    padding-top: $spacer-2
    padding-right: $spacer
    color: $body-color

    p:last-child
      margin: 0

  .InvoicePeriod__table
    margin: 0

  .InvoicePeriod__table__footer
    border-top: 1px solid $primary

    time
      display: block
      font-weight: normal

  .InvoicePeriod__table__main
    thead
      th
        background: $tertiary

    tr:nth-child(even)
      background: $gray-100

  .InvoicePeriod__table__vat
    th, td
      font-weight: normal !important
      color: $body-color
</style>
